import { useEffect } from 'react'
import { isNumber } from 'lodash'

import example from '../assets/images/normal-distribution-curve/example.png'

import { usePersist } from './use-persist'
import { useSystemContext } from '../context/system-context'
import { mutation } from '../graphql/api'
import { useSetState } from './use-set-state'
import { localStorage } from '../utils/local-storage'

export const useCategories = ({ analyse }) => {
  const { categories, dimensions } = useSystemContext()

  const [data, setData] = useSetState({
    seen: [],
    estimations: {},
    time_needed_in_ms: 0,
    isLoading: true
  })

  useEffect(() => {
    // wait for the analyse
    if (!analyse) return

    const { _temp } = JSON.parse(analyse.input || '{}')

    if (_temp) setData({ ..._temp, isLoading: false })
    else setData({ isLoading: false })
  }, [analyse, setData])

  const updateTempInput = ({ seen, estimations }) => {
    const _temp = { ...data }

    if (seen) _temp.seen = seen
    if (estimations) _temp.estimations = estimations

    const now = new Date()
    const last_interaction = new Date(localStorage.getItem('last-interaction'))

    _temp.time_needed_in_ms =
      data.time_needed_in_ms + (now.getTime() - last_interaction.getTime())

    localStorage.setItem('last-interaction', now.toISOString())
    setData({ time_needed_in_ms: _temp.time_needed_in_ms })

    mutation({
      mutation: 'updateCareerAnalyse',
      input: { id: analyse.id, input: JSON.stringify({ _temp }) },
      authMode: 'AWS_IAM'
    })
  }

  const [showInfoIconHint, setShowInfoIconHint] = usePersist({
    key: `info-icon-hint`,
    initialState: true
  })

  const { seen, estimations, isLoading, time_needed_in_ms } = data

  const result = {
    INTRO_0: {
      key: 'INTRO_0',
      title: 'intro.0.title',
      description: 'intro.0.description'
    },
    INTRO_1: {
      key: 'INTRO_1',
      title: 'intro.1.title',
      description: 'intro.1.description'
    },
    INTRO_2: {
      key: 'INTRO_2',
      title: 'intro.2.title',
      description: 'intro.2.description',
      attention: 'intro.2.attention'
    },
    INTRO_3: {
      key: 'INTRO_3',
      title: 'intro.3.title',
      description: 'intro.3.description',
      description_2: 'intro.3.description_2',
      attention: 'intro.3.attention',
      example
    },
    PERSONALITY: {
      ...categories.data['1'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 1)
        .map((key, index) => ({
          key,
          // the first dimension should be seen by default
          seen: index === 0 || seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          showInfoIconHint: index === 0 ? showInfoIconHint : false,
          setShowInfoIconHint: index === 0 ? setShowInfoIconHint : () => {},
          ...dimensions.data[key]
        }))
    },
    SKILLS: {
      ...categories.data['2'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 2)
        .map((key) => ({
          key,
          seen: seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          showInfoIconHint: false,
          setShowInfoIconHint: () => undefined,
          ...dimensions.data[key]
        }))
    },
    INTERESTS: {
      ...categories.data['3'],
      dimensions: Object.keys(dimensions.data)
        .filter((key) => dimensions.data[key].category === 3)
        .map((key) => ({
          key,
          seen: seen.includes(key),
          estimation: isNumber(estimations[key]) ? estimations[key] : -1,
          showInfoIconHint: false,
          setShowInfoIconHint: () => undefined,
          ...dimensions.data[key]
        }))
    },
    OVERVIEW_INTRO: {
      key: 'OVERVIEW_INTRO',
      title: 'overview_intro.title',
      description: 'overview_intro.description'
    },
    OUTRO: {
      key: 'OUTRO',
      title: 'outro.title',
      description: 'outro.description',
      description_2: 'outro.description_2'
    }
  }

  return {
    isLoading,
    categories: result,
    time_needed_in_ms,
    updateEstimation: ({ key, value }) => {
      const e = { ...estimations, [key]: value }

      setData({ estimations: e })
      updateTempInput({ estimations: e })
    },
    setSeen: (key) => {
      const s = [...seen.filter((d) => d !== key), key]

      setData({ seen: s })
      updateTempInput({ seen: s })
    }
  }
}
