import { useEffect, useState } from 'react'
import axios from 'axios'

import { notifyBugsnag } from '../utils/bugsnag'
import { BUILD_ENV } from '../utils/helpers'

const APP_SYNC_DEVELOP_URL =
  'https://fzjfyo5hf5bc5ickjyeidgolu4.appsync-api.eu-central-1.amazonaws.com/graphql'
const APP_SYNC_STAGING_URL =
  'https://gj3ijh3ggvbvngfedfxrgzlyx4.appsync-api.eu-central-1.amazonaws.com/graphql'
const APP_SYNC_PRODUCTION_URL =
  'https://wkrzv6awlzbotipe3bvjtbymcq.appsync-api.eu-central-1.amazonaws.com/graphql'

const getAppSyncUrl = () => {
  switch (BUILD_ENV) {
    case 'develop':
      return APP_SYNC_DEVELOP_URL
    case 'staging':
      return APP_SYNC_STAGING_URL
    default:
      return APP_SYNC_PRODUCTION_URL
  }
}

export const useDetectFirewall = () => {
  const [status, setStatus] = useState('INITIAL')

  useEffect(() => {
    const fetchAppSync = async () => {
      try {
        const response = await axios.get(getAppSyncUrl(), {
          validateStatus: (status) => {
            return status === 400
          }
        })

        // the HTTP 403 Forbidden response status code indicates
        // that the server understands the request but refuses
        // to authorize it
        // the access is tied to the application logic,
        // such as insufficient rights to a resource

        if (response.status === 403) {
          setStatus('ERROR_403')
          notifyBugsnag(
            new Error(`403 / forbidden / ${JSON.stringify(response)}`)
          )
          return
        }

        setStatus('SUCCESS')
      } catch (err) {
        notifyBugsnag(err)
        setStatus('ERROR')
      }
    }

    fetchAppSync()
  }, [])

  return { status }
}
