import './App.css'
import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Amplify } from 'aws-amplify'

import awsconfig from './aws-exports'
import Error404 from './pages/error-404'
import Layout from './components/layout'
import { BUILD_ENV, NODE_ENV } from './utils/helpers'
import Main from './pages/main'
import { query } from './graphql/api'
import { SystemContextProvider } from './context/system-context'
import ActivityIndicator from './components/activity-indicator'

Amplify.configure({
  ...awsconfig,
  Analytics: { autoSessionRecord: false }
})

// eslint-disable-next-line no-console
console.log({ BUILD_ENV, NODE_ENV })

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [system, setSystem] = useState(null)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return

    if (
      !['develop', 'staging', 'production'].includes(
        process.env.REACT_APP_USER_BRANCH
      )
    ) {
      throw new Error('REACT_APP_USER_BRANCH has a wrong value!')
    }
  }, [])

  useEffect(() => {
    Promise.all([
      query({
        query: 'getSystem',
        variables: { id: 'CATEGORIES' },
        authMode: 'AWS_IAM'
      }),
      query({
        query: 'getSystem',
        variables: { id: 'DIMENSIONS_REQUIREMENTS_ANALYSIS' },
        authMode: 'AWS_IAM'
      }),
      query({
        query: 'getSystem',
        variables: { id: 'ESTIMATIONS_REQUIREMENTS_ANALYSIS' },
        authMode: 'AWS_IAM'
      })
    ]).then(([categories, dimensions, estimations]) => {
      categories.data = JSON.parse(categories.data)
      dimensions.data = JSON.parse(dimensions.data)
      estimations.data = JSON.parse(estimations.data)

      setSystem({ categories, dimensions, estimations })
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <div className='flex justify-center pt-64'>
        <ActivityIndicator />
      </div>
    )
  }

  return (
    <SystemContextProvider system={system}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <Main />
              </Layout>
            }
          />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </SystemContextProvider>
  )
}

export default App
