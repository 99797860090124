/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "fd85683a96ca46e8b0b8b065b0e0b833",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "fd85683a96ca46e8b0b8b065b0e0b833",
            "region": "eu-central-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "fd85683a96ca46e8b0b8b065b0e0b833",
                "region": "eu-central-1"
            }
        },
        "Email": {
            "AWSPinpoint": {
                "appId": "fd85683a96ca46e8b0b8b065b0e0b833",
                "region": "eu-central-1"
            }
        },
        "SMS": {
            "AWSPinpoint": {
                "appId": "fd85683a96ca46e8b0b8b065b0e0b833",
                "region": "eu-central-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://csgnda4x0m.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "restify",
            "endpoint": "https://6hsjl256ka.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gj3ijh3ggvbvngfedfxrgzlyx4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-th3lxq25fvac7cgkt4x4usxf74",
    "aws_cognito_identity_pool_id": "eu-central-1:91de4f47-8d9f-4d6a-9346-00d7a29f5592",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_N6oOFcPGC",
    "aws_user_pools_web_client_id": "40dupnurvip41v30605ch3gqgv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "aivy-user-content-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
