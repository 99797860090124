export const BUILD_ENV = process.env.REACT_APP_USER_BRANCH || 'develop'
export const NODE_ENV = process.env.NODE_ENV

export const RESTIFY_DEV_URL =
  'https://ip28t9adxa.execute-api.eu-central-1.amazonaws.com'
export const RESTIFY_STAGING_URL =
  'https://6hsjl256ka.execute-api.eu-central-1.amazonaws.com'
export const RESTIFY_PROD_URL = 'https://restify.aivy.app'

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const generateUUIDV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
