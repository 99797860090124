import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { getI18n, useTranslation } from 'react-i18next'
import { motion, useAnimationControls, animate } from 'framer-motion'
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

import Typo from '../../typo'
import EstimationSlices from './estimation-slices'
import Arrow from './arrow'
import Button from '../../button'
import { trackEvent } from '../../../utils/analytics'
import { useMount } from '../../../hooks/use-mount'
import AnalyseInfoCard from './analyse-info-card'
import Modal from '../../modal'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import { classNames } from '../../../utils/helpers'

const INITIAL = 'INITIAL'
const ESTIMATION = 'ESTIMATION'

const estimationContainerStyle = {
  position: 'relative',
  marginTop: 20,
  padding: '21px 35px',
  background: '#EBEBEB',
  maxWidth: 738,
  borderRadius: 15
}

const ShowMoreContainer = ({ className, description, handleClose }) => (
  <div
    style={{ borderRadius: 15 }}
    className={classNames('bg-yellow-50 shadow px-6 py-5 z-10', className)}
  >
    <Typo type='t2'>{description}</Typo>
    <div className='absolute top-2 right-2'>
      <XMarkIcon onClick={handleClose} className='w-6 h-6 cursor-pointer' />
    </div>
  </div>
)

ShowMoreContainer.propTypes = {
  className: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

const Analyse = ({ currentDimension, nextDimension, forward }) => {
  const { t } = useTranslation()
  const { language } = getI18n()
  const { state, career, updateEstimation } = useAnalyseStateContext()

  const {
    key,
    property,
    intro,
    question,
    scaleEndpoints,
    estimation,
    showInfoIconHint,
    setShowInfoIconHint
  } = currentDimension

  const { isMobile } = useIsMobile()

  const [analyseState, setAnalyseState] = useState(INITIAL)
  const [displayAnalyseInfoCard, setDisplayAnalyseInfoCard] = useState(false)
  const [showMoreLeft, setShowMoreLeft] = useState(false)
  const [showMoreRight, setShowMoreRight] = useState(false)

  const introRef = useRef()
  const introControls = useAnimationControls()
  const estimationControls = useAnimationControls()

  const animateEstimation = useCallback(() => {
    animate(480, 738, {
      duration: 0.3,
      onUpdate: (value) => {
        introRef.current.style.maxWidth = `${value.toFixed(2)}px`
      }
    })

    introControls.start({ y: 0 }, { duration: 0.3 })
    setTimeout(
      () => estimationControls.start({ opacity: 1 }, { duration: 0.3 }),
      1000 * 0.3
    )
  }, [introControls, estimationControls])

  useMount(() => {
    // animate
    setAnalyseState(ESTIMATION)
  })

  useEffect(() => {
    if (isMobile) return
    // animate after analyse state was applied to the ui
    if (analyseState === ESTIMATION) animateEstimation()
  }, [isMobile, analyseState, animateEstimation])

  if (isMobile) {
    return (
      <>
        <div className='px-4 sm:px-16'>
          <Typo type='h12' center>
            {property[language]}
          </Typo>
          {intro && (
            <div className='mt-1'>
              <Typo center type='t2'>
                {intro[language]}
              </Typo>
            </div>
          )}
          {analyseState === ESTIMATION && (
            <motion.div style={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <div className='mt-4 bg-slate-50 p-4 rounded-lg'>
                <Typo type='h2' center style={{ padding: '0px 16px' }} markdown>
                  {question[language].replace('{{career_title}}', career.title)}
                </Typo>
                <div
                  style={{
                    position: 'relative',
                    marginTop: 40,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <EstimationSlices
                      estimation={estimation}
                      updateEstimation={(est) => {
                        updateEstimation({ key, value: est })
                        forward()

                        trackEvent('estimation_selected', {
                          dimension: state,
                          estimation: est
                        })
                      }}
                    />
                  </div>
                </div>
                <div style={{ position: 'relative', marginTop: 16 }}>
                  <div className='flex justify-between'>
                    <Typo type='t1' semibold>
                      {scaleEndpoints.left.title[language]}
                    </Typo>
                    <Typo type='t1' semibold right>
                      {scaleEndpoints.right.title[language]}
                    </Typo>
                  </div>
                </div>
                <div className='hidden mt-1 sm:flex justify-between'>
                  {scaleEndpoints.description ? (
                    <Typo type='t2' markdown>
                      {scaleEndpoints.description[language]}
                    </Typo>
                  ) : (
                    <>
                      <div style={{ maxWidth: 285, marginRight: 16 }}>
                        <Typo type='t2'>
                          {scaleEndpoints.left.description[language]}
                        </Typo>
                      </div>
                      <div style={{ maxWidth: 285, marginLeft: 16 }}>
                        <Typo type='t2' right>
                          {scaleEndpoints.right.description[language]}
                        </Typo>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='mt-8 flex justify-center'>
                <Button
                  type='forward'
                  text={t('global.next')}
                  disabled={estimation === -1}
                  onClick={forward}
                />
              </div>
              <div
                onClick={
                  showInfoIconHint
                    ? undefined
                    : () => setDisplayAnalyseInfoCard(true)
                }
                className={classNames(
                  'absolute w-8 h-8 top-0 right-4',
                  'rounded-full text-gray-700 hover:text-gray-900 cursor-pointer',
                  showInfoIconHint
                    ? 'z-20 bg-white scale-150 top-4 right-8'
                    : ''
                )}
              >
                <InformationCircleIcon className='w-8 h-8 text-gray-900' />
              </div>
            </motion.div>
          )}
        </div>
        <Modal
          open={displayAnalyseInfoCard}
          setOpen={setDisplayAnalyseInfoCard}
        >
          <div className='flex justify-center mt-12 mb-16'>
            <AnalyseInfoCard currentDimension={currentDimension} />
          </div>
        </Modal>
        <Modal
          open={showInfoIconHint && analyseState === ESTIMATION}
          setOpen={() => setShowInfoIconHint(false)}
        >
          <div className='flex flex-col items-center my-8 mx-4'>
            <Typo type='h2' translate>
              analyse.info_icon_intro_title
            </Typo>
            <div className='h-4' />
            <Typo type='t1' translate center>
              analyse.info_icon_intro
            </Typo>
            <div className='h-4' />
          </div>
        </Modal>
      </>
    )
  }

  return (
    <>
      <div className='mx-16'>
        <motion.div
          ref={introRef}
          style={{ maxWidth: 480, y: 300 }}
          animate={introControls}
        >
          <Typo type='h1'>{property[language]}</Typo>
          {intro && (
            <div className='w-9/12 mt-1'>
              <Typo type='t2'>{intro[language]}</Typo>
            </div>
          )}
        </motion.div>
        {analyseState === ESTIMATION && (
          <motion.div style={{ opacity: 0 }} animate={estimationControls}>
            <div style={estimationContainerStyle}>
              <div style={{ opacity: 1 }}>
                <Typo type='h2' center style={{ padding: '0px 40px' }} markdown>
                  {question[language].replace('{{career_title}}', career.title)}
                </Typo>
              </div>
              <div
                style={{
                  position: 'relative',
                  marginTop: 32,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >
                <div style={{ display: 'flex', opacity: 1, maxWidth: 668 }}>
                  <EstimationSlices
                    estimation={estimation}
                    updateEstimation={(est) => {
                      updateEstimation({ key, value: est })
                      forward()

                      trackEvent('estimation_selected', {
                        dimension: state,
                        estimation: est
                      })
                    }}
                  />
                </div>
              </div>
              <div style={{ position: 'relative', marginTop: 16 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typo
                    type='h2'
                    nowrap
                    onClick={() => setShowMoreLeft(!showMoreLeft)}
                    className='cursor-pointer hover:text-indigo-600'
                  >
                    {scaleEndpoints.left.title[language]}
                  </Typo>
                  <div style={{ width: '100%', padding: '0 12px' }}>
                    <Arrow />
                  </div>
                  <Typo
                    type='h2'
                    nowrap
                    onClick={() => setShowMoreRight(!showMoreRight)}
                    className='cursor-pointer hover:text-indigo-600'
                  >
                    {scaleEndpoints.right.title[language]}
                  </Typo>
                </div>
              </div>
              <div className='mt-1'>
                {scaleEndpoints.description ? (
                  <Typo type='t2' markdown>
                    {scaleEndpoints.description[language]}
                  </Typo>
                ) : (
                  <div className='relative flex justify-between'>
                    <Typo
                      type='t2'
                      style={{ maxWidth: 285, marginRight: 16 }}
                      onClick={() => setShowMoreLeft(!showMoreLeft)}
                      className='cursor-pointer hover:text-indigo-600'
                    >
                      {scaleEndpoints.left.description[language]}
                    </Typo>
                    {showMoreLeft && (
                      <ShowMoreContainer
                        className='absolute top-0 -left-4 w-10/12'
                        description={
                          scaleEndpoints.left.description_long[language]
                        }
                        handleClose={() => setShowMoreLeft(false)}
                      />
                    )}
                    <Typo
                      type='t2'
                      right
                      style={{ maxWidth: 285, marginLeft: 16 }}
                      onClick={() => setShowMoreRight(!showMoreRight)}
                      className='cursor-pointer hover:text-indigo-600'
                    >
                      {scaleEndpoints.right.description[language]}
                    </Typo>
                    {showMoreRight && (
                      <ShowMoreContainer
                        className='absolute top-0 -right-4 w-10/12'
                        description={
                          scaleEndpoints.right.description_long[language]
                        }
                        handleClose={() => setShowMoreRight(false)}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* <div
                onClick={
                  showInfoIconHint
                    ? undefined
                    : () => setDisplayAnalyseInfoCard(true)
                }
                className={classNames(
                  'absolute top-2 right-2 w-8 h-8 rounded-full text-gray-700 hover:text-gray-900',
                  showInfoIconHint
                    ? 'z-20 bg-white scale-150 top-4 right-8'
                    : 'cursor-pointer'
                )}
              >
                <InformationCircleIcon />
              </div> */}
            </div>
            <div className='mt-4 flex justify-center'>
              <Button
                type='forward'
                text={
                  nextDimension
                    ? nextDimension.property[language]
                    : t('analyse.go_to_overview')
                }
                disabled={estimation === -1}
                onClick={forward}
              />
            </div>
          </motion.div>
        )}
      </div>
      <Modal open={displayAnalyseInfoCard} setOpen={setDisplayAnalyseInfoCard}>
        <div className='flex justify-center mt-12 mb-16'>
          <AnalyseInfoCard currentDimension={currentDimension} />
        </div>
      </Modal>
      <Modal
        open={showInfoIconHint && analyseState === ESTIMATION}
        setOpen={() => setShowInfoIconHint(false)}
        width='sm:max-w-xl'
      >
        <div className='flex flex-col items-center my-8 mx-4'>
          <Typo type='h2' translate>
            analyse.info_icon_intro_title
          </Typo>
          <div className='h-4' />
          <Typo type='t1' translate center>
            analyse.info_icon_intro
          </Typo>
          <div className='h-4' />
        </div>
      </Modal>
    </>
  )
}

Analyse.propTypes = {
  currentDimension: PropTypes.object.isRequired,
  nextDimension: PropTypes.object,
  forward: PropTypes.func.isRequired
}

Analyse.defaultProps = {
  nextDimension: null
}

export default Analyse
