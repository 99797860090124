import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimationControls } from 'framer-motion'

import Typo from '../typo'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import { trackEvent } from '../../utils/analytics'
import { useAnalyseStateContext } from '../../context/analyse-state-context'
import { useIsMobile } from '../../hooks/use-is-mobile'

const Intro = ({ states, onFinish }) => {
  const { t } = useTranslation()
  const { state, setState, categories, career, partner } =
    useAnalyseStateContext()
  const { key, title, description, example, description_2, attention } =
    categories[state]

  const controls = useAnimationControls()

  const { isMobile } = useIsMobile()

  useEffect(() => {
    if (state !== key) return

    controls.start({ opacity: 1 })
  }, [state, key, controls])

  const handleOnClick = (onClick) => {
    controls.start({ opacity: 0 }).then(onClick)
  }

  return (
    <motion.div
      style={{ opacity: 0.4 }}
      animate={controls}
      transition={{ duration: 0.2 }}
    >
      <div className='m-8 mb-32 md:m-16' style={{ maxWidth: 738 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typo type='h1' translate>
            {title}
          </Typo>
        </div>
        <div
          style={{
            marginTop: 27,
            borderRadius: 15,
            ...(!isMobile && {
              padding: '21px 35px',
              background: '#EBEBEB'
            })
          }}
        >
          <Typo type='t1' markdown>
            {t(description, {
              career_title: career.title,
              partner_name: partner.dispay_name || partner.name,
              dimensions_length:
                Object.keys(categories.PERSONALITY.dimensions).length +
                Object.keys(categories.SKILLS.dimensions).length +
                Object.keys(categories.INTERESTS.dimensions).length
            })}
          </Typo>
          {example && <img src={example} className='pt-4 md:px-8' />}
          {description_2 && (
            <Typo type='t1' markdown style={{ marginTop: 16 }}>
              {t(description_2)}
            </Typo>
          )}
          {attention && (
            <Typo type='t1' markdown italic style={{ marginTop: 8 }}>
              {t(attention)}
            </Typo>
          )}
        </div>
        <div
          style={{
            marginTop: 27,
            display: 'flex',
            justifyContent: 'center',
            gap: 32
          }}
        >
          {states[states.indexOf(state) - 1] && (
            <Button
              type='back'
              text='global.back'
              translate
              onClick={() =>
                handleOnClick(() => {
                  const back = states[states.indexOf(state) - 1]
                  setState(back)
                  trackEvent('intro_back', { state: back })
                })
              }
            />
          )}
          {states[states.indexOf(state) + 1] && (
            <Button
              text='global.next'
              translate
              onClick={() =>
                handleOnClick(() => {
                  const next = states[states.indexOf(state) + 1]
                  setState(next)
                  trackEvent('intro_next', { state: next })
                })
              }
            />
          )}
          {!states[states.indexOf(state) + 1] && (
            <Button
              text='global.start'
              translate
              onClick={() =>
                handleOnClick(() => {
                  onFinish()
                })
              }
            />
          )}
        </div>
      </div>
    </motion.div>
  )
}

Intro.propTypes = {
  states: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default Intro
