import { useState } from 'react'
import { getI18n, useTranslation } from 'react-i18next'
import ActivityIndicator from 'react-spinners/BeatLoader'
import { motion, useScroll } from 'framer-motion'

import Typo from '../../typo'
import OverviewItem from './overview-item'
import Button from '../../button'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import { sendSlackMessage } from '../../../utils/slack'
import { notifyBugsnag } from '../../../utils/bugsnag'

const progressbar = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: 8,
  transformOrigin: '0%',
  background: '#4460D4',
  zIndex: 200
}

const list = {
  visible: {
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.7,
      delayChildren: 0.3,
      staggerChildren: 0.05
    }
  },
  hidden: {
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.3
    }
  }
}

const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 }
  },
  hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
}

const Overview = () => {
  const { t } = useTranslation()
  const { language } = getI18n()
  const { setState, finishAnalyse, categories, career, partner } =
    useAnalyseStateContext()

  const { isMobile } = useIsMobile()
  const { scrollYProgress } = useScroll()

  const [isLoading, setIsLoading] = useState(false)

  const forward = () => {
    setIsLoading(true)
    finishAnalyse()
      .then(() => {
        sendSlackMessage({
          message: `${partner.name} hat soeben eine Anforderungsanalyse zum Profil ${career.title} abgeschlossen.`
        })

        setState('OUTRO')
      })
      .catch((err) => notifyBugsnag(err))
      .finally(() => setIsLoading(false))
  }

  if (isMobile) {
    return (
      <>
        <motion.div style={{ scaleX: scrollYProgress, ...progressbar }} />
        <div className='px-8 pb-16 flex flex-col max-w-md mx-auto'>
          <Typo type='h12'>{categories.PERSONALITY.name[language]}</Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 8 }}
          >
            {categories.PERSONALITY.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>

          <Typo type='h12' style={{ marginTop: 16 }}>
            {categories.SKILLS.name[language]}
          </Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 8 }}
          >
            {categories.SKILLS.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>

          <Typo type='h12' style={{ marginTop: 16 }}>
            {categories.INTERESTS.name[language]}
          </Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 8 }}
          >
            {categories.INTERESTS.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>

          <div className='mt-4 flex justify-center'>
            {isLoading ? (
              <ActivityIndicator color='#4461D4' />
            ) : (
              <Button
                type='forward'
                text={t('analyse.finish_analyse')}
                onClick={forward}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <motion.div style={{ scaleX: scrollYProgress, ...progressbar }} />
      <div style={{ maxWidth: 1024 }} className='mx-auto px-16 py-40 2xl:py-8'>
        <div className='mt-12'>
          <Typo type='h1' style={{ marginTop: 32 }}>
            {categories.PERSONALITY.name[language]}
          </Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 32 }}
          >
            {categories.PERSONALITY.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>

          <Typo type='h1' style={{ marginTop: 32 }}>
            {categories.SKILLS.name[language]}
          </Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 32 }}
          >
            {categories.SKILLS.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>

          <Typo type='h1' style={{ marginTop: 32 }}>
            {categories.INTERESTS.name[language]}
          </Typo>
          <motion.ul
            initial='hidden'
            animate='visible'
            variants={list}
            style={{ marginTop: 32 }}
          >
            {categories.INTERESTS.dimensions.map((dimension, index) => (
              <motion.li variants={item} key={index}>
                <OverviewItem dimension={dimension} />
                <div style={{ height: 32 }} />
              </motion.li>
            ))}
          </motion.ul>
        </div>
        <div className='mt-4 my-32 flex justify-center'>
          {isLoading ? (
            <ActivityIndicator color='#4461D4' />
          ) : (
            <Button
              type='forward'
              text={t('analyse.finish_analyse')}
              onClick={forward}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Overview
