import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AivyIcon from '../assets/images/aivyicon.svg'
import { classNames } from '../utils/helpers'

const Maintenance = ({
  state,
  withCode,
  withReasons,
  withSolutions,
  withHint
}) => {
  const { t } = useTranslation()

  const prefix = `${state || 'ERROR_UNHANDLED'}.`

  const { code, title, message, contact_support, reasons, solutions, hint } = {
    code: withCode ? t(prefix + 'code') : null,
    title: t(prefix + 'title'),
    message: t(prefix + 'message'),
    contact_support: t(prefix + 'contact_support'),
    reasons: withReasons
      ? {
          title: t(prefix + 'reasons.title'),
          paragraphs: t(prefix + 'reasons.paragraphs')
        }
      : null,
    solutions: withSolutions
      ? {
          title: t(prefix + 'solutions.title'),
          paragraphs: t(prefix + 'solutions.paragraphs')
        }
      : null,
    hint: withHint ? t(prefix + 'hint') : null
  }

  return (
    <>
      <div
        className={classNames(
          'flex min-h-full flex-col bg-white pb-12',
          reasons || solutions ? 'mt-16' : 'mt-24 pt-16'
        )}
      >
        <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
          <div className='flex flex-shrink-0 justify-center'>
            <a href='/' className='inline-flex'>
              <span className='sr-only'>aivy-icon</span>
              <img className='h-16 w-auto' src={AivyIcon} alt='' />
            </a>
          </div>
          <div className='pt-8'>
            {code && (
              <p className='my-2 text-2xl xsm:text-5xl text-center font-extrabold text-indigo-600'>
                {code}
              </p>
            )}
            <div className='flex flex-col items-center text-center'>
              <h1 className='mt-2 font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl'>
                {title}
              </h1>
              <p className='mt-4 max-w-md text-base text-gray-900'>{message}</p>
            </div>
          </div>
        </main>

        {reasons && (
          <div className='mx-auto mt-8 flex flex-col justify-center'>
            <h1 className='font-bold text-gray-900 text-xl'>{reasons.title}</h1>
            <ul className='ml-4 list-disc'>
              {reasons.paragraphs.map((paragraph, index) => (
                <li
                  key={index}
                  className='mt-1.5 max-w-lg text-sm text-gray-900'
                >
                  {paragraph}
                </li>
              ))}
            </ul>
          </div>
        )}

        {solutions && (
          <div className='mx-auto mt-8 flex flex-col justify-center'>
            <h1 className='font-bold text-gray-900 text-xl'>
              {solutions.title}
            </h1>
            <ul className='ml-4 list-disc'>
              {solutions.paragraphs.map((paragraph, index) => (
                <li
                  key={index}
                  className='mt-1.5 max-w-lg text-sm text-gray-900'
                >
                  {paragraph}
                </li>
              ))}
            </ul>
          </div>
        )}

        {hint && (
          <div className='mx-auto mt-8 flex flex-col justify-center'>
            <p className='-ml-4 max-w-lg text-sm text-gray-900'>{hint}</p>
          </div>
        )}

        <div className={classNames(reasons || solutions ? 'h-8' : 'h-16')} />

        <footer className='mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8'>
          <nav className='flex justify-center space-x-4'>
            <a
              href={`mailto:help@aivy.app?subject=[SUPPORT - AIVY-REQUIREMENTS-ANALYSIS]&body=(${window.location.href} - do not remove!)`}
              className='text-md font-medium text-indigo-500 hover:text-indigo-600'
            >
              {contact_support}
            </a>
          </nav>
        </footer>
      </div>
    </>
  )
}

Maintenance.propTypes = {
  state: PropTypes.string,
  withCode: PropTypes.bool,
  withReasons: PropTypes.bool,
  withSolutions: PropTypes.bool,
  withHint: PropTypes.bool
}

Maintenance.defaultProps = {
  state: undefined,
  withCode: false,
  withReasons: false,
  withSolutions: false,
  withHint: false
}

export default Maintenance
