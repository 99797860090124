import { getI18n } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSystemContext } from '../../../context/system-context'
import Typo from '../../typo'

const EstimationText = ({ estimation, category, dimension_key }) => {
  const { language } = getI18n()
  const { estimations } = useSystemContext()

  const generateText = () => {
    let result
    const levels = estimations.data[category].levels
    const translation_for_dimension = estimations.data[category][dimension_key]

    for (const key in translation_for_dimension) {
      if (key.includes('-')) {
        const [min, max] = key.split('-').map(Number)
        if (estimation >= min && estimation <= max) {
          result = translation_for_dimension[key][language]
          break
        }
      } else if (Number(key) === estimation) {
        result = translation_for_dimension[key][language]
        break
      }
    }

    if (levels) {
      const { level_0, level_1 } = levels[estimation]
      result = result.replace('{{level_0}}', level_0[language])
      result = result.replace('{{level_1}}', level_1[language])
    }

    if (result) return result

    const not_found_for = [
      `category: ${category}`,
      `dimension_key: ${dimension_key}`,
      `estimation: ${estimation}`
    ].join(', ')

    throw new Error(`translation not found / ${not_found_for}`)
  }

  return (
    <Typo type='t2' markdown>
      {generateText()}
    </Typo>
  )
}

EstimationText.propTypes = {
  estimation: PropTypes.number.isRequired,
  category: PropTypes.number.isRequired,
  dimension_key: PropTypes.string.isRequired
}

export default EstimationText
