import PropTypes from 'prop-types'
import { getI18n } from 'react-i18next'

import Typo from '../../typo'
import EstimationSlices from './estimation-slices'
import Arrow from './arrow'
import { useAnalyseStateContext } from '../../../context/analyse-state-context'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import { trackEvent } from '../../../utils/analytics'

const AnalyseInfoCard = ({ currentDimension }) => {
  const { language } = getI18n()
  const { career, updateEstimation } = useAnalyseStateContext()

  const { isMobile } = useIsMobile()

  const { key, property, intro, question, scaleEndpoints, estimation } =
    currentDimension

  if (isMobile) {
    return (
      <div>
        <Typo type='h12' center>
          {property[language]}
        </Typo>
        {intro && (
          <Typo type='t2' center style={{ marginTop: 12 }}>
            {intro[language]}
          </Typo>
        )}
        <div className='mt-8'>
          <div>
            <Typo type='h2' center style={{ padding: '0px 40px' }} markdown>
              {question[language].replace('{{career_title}}', career.title)}
            </Typo>
          </div>
          <div
            style={{
              position: 'relative',
              marginTop: 23,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <EstimationSlices
              updateEstimation={(est) => {
                updateEstimation({ key, value: est })
                trackEvent('estimation_selected_from_info_card', {
                  dimension: key,
                  estimation: est
                })
              }}
              estimation={estimation}
            />
          </div>
          {scaleEndpoints.description && (
            <div style={{ position: 'relative', marginTop: 12 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typo type='h2' semibold>
                  {scaleEndpoints.left.title[language]}
                </Typo>
                <div style={{ width: '100%', padding: '0 12px' }}>
                  <Arrow />
                </div>
                <Typo type='h2' semibold>
                  {scaleEndpoints.right.title[language]}
                </Typo>
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: 8,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {scaleEndpoints.description ? (
              <Typo type='t2' markdown>
                {scaleEndpoints.description[language]}
              </Typo>
            ) : (
              <div>
                <div className='h-4' />
                <Typo type='h2' semibold>
                  {scaleEndpoints.left.title[language]}
                </Typo>
                <Typo type='t2' style={{ marginTop: 4 }}>
                  {scaleEndpoints.left.description_long[language]}
                </Typo>
                <div className='h-4' />
                <Typo type='h2' semibold>
                  {scaleEndpoints.right.title[language]}
                </Typo>
                <Typo type='t2' style={{ marginTop: 4 }}>
                  {scaleEndpoints.right.description_long[language]}
                </Typo>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: 738 }}>
      <Typo type='h1'>{property[language]}</Typo>
      {intro && (
        <Typo type='t2' style={{ marginTop: 12, maxWidth: 640 }}>
          {intro[language]}
        </Typo>
      )}
      <div
        style={{
          position: 'relative',
          marginTop: 27,
          padding: '21px 35px',
          background: '#EBEBEB',
          maxWidth: 738,
          borderRadius: 15
        }}
      >
        <div>
          <Typo type='h2' center style={{ padding: '0px 40px' }} markdown>
            {question[language].replace('{{career_title}}', career.title)}
          </Typo>
        </div>
        <div
          style={{
            position: 'relative',
            marginTop: 23,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <EstimationSlices
            updateEstimation={(est) => {
              updateEstimation({ key, value: est })
              trackEvent('estimation_selected_from_info_card', {
                dimension: key,
                estimation: est
              })
            }}
            estimation={estimation}
          />
        </div>
        <div style={{ position: 'relative', marginTop: 16 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typo type='h2' nowrap>
              {scaleEndpoints.left.title[language]}
            </Typo>
            <div style={{ width: '100%', padding: '0 12px' }}>
              <Arrow />
            </div>
            <Typo type='h2' nowrap>
              {scaleEndpoints.right.title[language]}
            </Typo>
          </div>
        </div>
        <div
          style={{
            marginTop: 12,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {scaleEndpoints.description ? (
            <Typo type='t2' markdown>
              {scaleEndpoints.description[language]}
            </Typo>
          ) : (
            <>
              <div style={{ maxWidth: 320, marginRight: 16 }}>
                <Typo type='t2'>
                  {scaleEndpoints.left.description_long[language]}
                </Typo>
              </div>
              <div style={{ maxWidth: 320, marginLeft: 16 }}>
                <Typo type='t2' right>
                  {scaleEndpoints.right.description_long[language]}
                </Typo>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

AnalyseInfoCard.propTypes = {
  currentDimension: PropTypes.object.isRequired
}

AnalyseInfoCard.defaultProps = {}

export default AnalyseInfoCard
