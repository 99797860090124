import { useState } from 'react'
import { getI18n } from 'react-i18next'
import PropTypes from 'prop-types'

import EstimationState from './estimation-state'
import Typo from '../../typo'
import Modal from '../../modal'
import { useIsMobile } from '../../../hooks/use-is-mobile'
import AnalyseInfoCard from '../analyse/analyse-info-card'
import EstimationText from './estimation-text'

const OverviewItem = ({ dimension }) => {
  const { language } = getI18n()
  const { isMobile } = useIsMobile()

  const { estimation, category, key: dimension_key } = dimension

  const [displayAnalyse, setDisplayAnalyse] = useState(false)

  if (isMobile) {
    return (
      <>
        <div className='w-full flex flex-col mx-auto max-w-sm'>
          <Typo type='h2'>{dimension.property[language]}</Typo>
          <div
            onClick={() => setDisplayAnalyse(true)}
            className='mt-4 cursor-pointer'
          >
            <EstimationState estimation={estimation} />
          </div>
          <div className='h-4' />
          <EstimationText {...{ estimation, category, dimension_key }} />
        </div>
        <Modal open={displayAnalyse} setOpen={setDisplayAnalyse}>
          <div className='h-12' />
          <div className='flex justify-center'>
            <AnalyseInfoCard currentDimension={dimension} />
          </div>
          <div className='h-16' />
        </Modal>
      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: 1024 }} className='flex relative'>
        <div className='pb-6 pt-4 px-6 w-full rounded-2xl bg-gray-50'>
          <Typo type='h2'>{dimension.property[language]}</Typo>
          <div className='pt-4' />
          <div className='flex'>
            <div
              onClick={() => setDisplayAnalyse(true)}
              className='mt-0 flex flex-col justify-center w-1/3 cursor-pointer'
            >
              <EstimationState estimation={estimation} />
            </div>
            <div className='ml-8 w-2/3 flex flex-col justify-center'>
              <EstimationText {...{ estimation, category, dimension_key }} />
            </div>
          </div>
        </div>
      </div>
      <Modal open={displayAnalyse} setOpen={setDisplayAnalyse}>
        <div className='h-12' />
        <div className='flex justify-center'>
          <AnalyseInfoCard currentDimension={dimension} />
        </div>
        <div className='h-16' />
      </Modal>
    </>
  )
}

OverviewItem.propTypes = {
  dimension: PropTypes.object.isRequired
}

export default OverviewItem
